import React, { useState } from "react"
import { useStaticQuery, graphql } from "gatsby"
import styled, { css } from "styled-components"
import BackgroundImage from "gatsby-background-image"

//Libraries
import { Trans, useTranslation } from "gatsby-plugin-react-i18next"

// Utils
import breakpoint from "utils/breakpoints/"
import { colors } from "utils/variables/"

// Components
import Observer from "components/observer/"
import Container from "components/container/"
import Button from "components/button/"

const StyledContainer = styled(Container)`
  max-width: 1440px;
  position: relative;
`

const StyledTeam = styled.section`
  padding: 320px 0 290px 0;
  overflow: hidden;

  ${breakpoint.medium`
    padding: 180px 0;
  `}

  .team__center-circle {
    width: 343px;
    height: 343px;
    position: relative;
    display: flex;
    align-items: center;
    padding: 16px;
    margin: 0 auto;
    background-color: ${colors.cream};
    color: ${colors.stone};
    font-size: 14px;
    line-height: 1.4em;
    text-align: center;
    border-radius: 50%;
    z-index: 101;

    @media screen and (max-width: 375px) {
      width: 270px;
      height: 270px;
    }

    ${breakpoint.small`
      width: 520px;
      height: 520px;
      font-size: 18px;
    `}

    > div {
      width: 100%;
    }

    h3 {
      color: ${colors.midnight};
    }

    p {
      max-width: 303px;
      display: none;
      margin: 16px auto 0 auto;

      ${breakpoint.small`
        display: block;
      `}
    }

    .team__button {
      margin-top: 24px;
    }
  }
`

const StyledTeamCircle = styled.div`
  width: ${props => (props.size ? props.size : "")};
  height: ${props => (props.size ? props.size : "")};
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  background-color: ${colors.supernova};
  background-image: ${props => (props.image ? `url('${props.image}')` : "")};
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 100%;
  z-index: 100;
  transition: all 1s;

  ${props =>
    props.visible
      ? css`
          transform: translate(
            ${props => (props.xMobile ? props.xMobile : "")},
            ${props => (props.yMobile ? props.yMobile : "")}
          );
        `
      : ``}

  @media screen and (max-width: 767px) {
    &[data-circle="4"] {
      width: 112px;
      height: 112px;
    }

    &[data-circle="6"] {
      width: 72px;
      height: 72px;
    }

    &[data-circle="7"] {
      width: 160px;
      height: 160px;
    }
  }

  ${breakpoint.small`
    display: block;

    ${props =>
      props.visible
        ? css`
            transform: translate(
              ${props => (props.x ? props.x : "")},
              ${props => (props.y ? props.y : "")}
            );
          `
        : ``}
  `}

  .image {
    border-radius: 50%;
  }
`

const TeamCircle = props => (
  <StyledTeamCircle {...props}>
    {props.fixed ? (
      <BackgroundImage
        className="image"
        fixed={props.fixed}
        style={{
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      />
    ) : (
      <BackgroundImage
        className="image"
        fluid={props.fluid}
        style={{
          width: "100%",
          height: "100%",
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      />
    )}
  </StyledTeamCircle>
)

const TeamImages = props => {
  const data = useStaticQuery(graphql`
    query {
      imageOne: file(relativePath: { eq: "homepage/team/team-1.jpg" }) {
        childImageSharp {
          fixed(width: 72, quality: 100) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      imageTwo: file(relativePath: { eq: "homepage/team/team-2.jpg" }) {
        childImageSharp {
          fixed(width: 168, quality: 100) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      imageThree: file(relativePath: { eq: "homepage/team/team-3.jpg" }) {
        childImageSharp {
          fixed(width: 112, quality: 100) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      imageFour: file(relativePath: { eq: "homepage/team/team-4.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 232, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      imageFive: file(relativePath: { eq: "homepage/team/team-5.jpg" }) {
        childImageSharp {
          fixed(width: 88, quality: 100) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      imageSix: file(relativePath: { eq: "homepage/team/team-6.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 104, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      imageSeven: file(relativePath: { eq: "homepage/team/team-7.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 232, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      imageEight: file(relativePath: { eq: "homepage/team/team-8.jpg" }) {
        childImageSharp {
          fixed(width: 72, quality: 100) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      imageNine: file(relativePath: { eq: "homepage/team/team-9.jpg" }) {
        childImageSharp {
          fixed(width: 160, quality: 100) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      imageTen: file(relativePath: { eq: "homepage/team/team-10.jpg" }) {
        childImageSharp {
          fixed(width: 112, quality: 100) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
    }
  `)
  return (
    <React.Fragment>
      {/* Right elements */}
      <TeamCircle
        data-circle="1"
        visible={props.hasAnimated}
        fixed={data.imageOne.childImageSharp.fixed}
        size="72px"
        xMobile="-64px"
        yMobile="360px"
        x="250px"
        y="-200px"
      />
      <TeamCircle
        data-circle="2"
        visible={props.hasAnimated}
        fixed={data.imageTwo.childImageSharp.fixed}
        size="168px"
        xMobile="90px"
        yMobile="270px"
        x="440px"
        y="-100px"
      />
      <TeamCircle
        data-circle="3"
        visible={props.hasAnimated}
        fixed={data.imageThree.childImageSharp.fixed}
        size="112px"
        xMobile="-135px"
        yMobile="220px"
        x="274px"
        y="200px"
      />
      <TeamCircle
        data-circle="4"
        visible={props.hasAnimated}
        fluid={data.imageFour.childImageSharp.fluid}
        size="232px"
        xMobile="135px"
        yMobile="-220px"
        x="640px"
        y="120px"
      />
      <TeamCircle
        data-circle="5"
        visible={props.hasAnimated}
        fixed={data.imageFive.childImageSharp.fixed}
        size="88px"
        x="656px"
        y="-238px"
      />

      {/* Left Elements */}
      <TeamCircle
        data-circle="6"
        visible={props.hasAnimated}
        fluid={data.imageSix.childImageSharp.fluid}
        size="104px"
        xMobile="64px"
        yMobile="-320px"
        x="-320px"
        y="-208px"
      />
      <TeamCircle
        data-circle="7"
        visible={props.hasAnimated}
        fluid={data.imageSeven.childImageSharp.fluid}
        size="232px"
        xMobile="-120px"
        yMobile="-240px"
        x="-424px"
        y="48px"
      />
      <TeamCircle
        data-circle="8"
        visible={props.hasAnimated}
        fixed={data.imageEight.childImageSharp.fixed}
        size="72px"
        x="-248px"
        y="216px"
      />
      <TeamCircle
        data-circle="9"
        visible={props.hasAnimated}
        fixed={data.imageNine.childImageSharp.fixed}
        size="160px"
        x="-672px"
        y="-152px"
      />
      <TeamCircle
        data-circle="10"
        visible={props.hasAnimated}
        fixed={data.imageTen.childImageSharp.fixed}
        size="112px"
        x="-632px"
        y="224px"
      />
    </React.Fragment>
  )
}

const Team = () => {
  const [hasAnimated, setAnimated] = useState(false)

  const handleVisibility = () => {
    if (!hasAnimated) {
      setAnimated(true)
    }
  }

  const { t } = useTranslation()

  return (
    <StyledTeam>
      <StyledContainer>
        <Observer onVisible={handleVisibility} threshold="0.7">
          <div className="team__center-circle">
            <div>
              <h3>
                <Trans>Meet the humans</Trans>
                <br />
                <Trans>of Indicius</Trans>
              </h3>
              <p>
                <Trans>
                  We believe innovation happens when both our team and our
                  clients can grow and thrive.
                </Trans>
              </p>
              <Button
                className="team__button"
                to="/about/"
                text={t("Get to know us")}
                backgroundColor={colors.indiblue}
                color={colors.white}
                hoverBackgroundColor={colors.midnight}
              />
            </div>
          </div>
        </Observer>

        <TeamImages hasAnimated={hasAnimated} />
      </StyledContainer>
    </StyledTeam>
  )
}

export default Team
