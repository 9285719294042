import React from "react"

// Libraries
import styled from "styled-components"
import { Trans, Link } from "gatsby-plugin-react-i18next"

// Utils
import { colors } from "utils/variables/"

// Icons
import IconArrow from "assets/icons/icon-arrow-right.inline.svg"

const StyledBlogCard = styled(Link)`
  height: 100%;
  position: relative;
  display: block;
  padding: 8px;
  background-color: ${colors.cream};
  box-sizing: border-box;
  transition: all 0.3s ease;

  &:hover {
    background-color: ${colors.midnight};

    .card__title {
      h4 {
        color: ${colors.supernova};
      }
      p {
        color: ${colors.white};
      }
    }

    .card__icon {
      svg {
        right: 146px;
        margin-right: 8px;
      }

      span {
        opacity: 1;
        transition: opacity 0.2s ease 0.1s;
      }
    }
  }

  .card__image {
    width: 100%;
    padding-bottom: 56%;
    background-size: cover;
    background-color: ${colors.cream};
  }

  .card__title {
    padding: 32px 20px 94px 20px;

    h4 {
      margin-right: 4px;
      margin-bottom: 8px;
      color: ${colors.stone};
      font-weight: 700;
      text-transform: uppercase;
    }
  }

  .card__icon {
    right: 32px;
    bottom: 32px;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    svg {
      position: absolute;
      right: 0;
      transition: all 0.3s ease;
    }

    span {
      color: ${colors.supernova};
      font-weight: 500;
      opacity: 0;
      transition: all 0s ease;
    }
  }
`

const BlogCard = props => {
  const { slug, className, categories, title } = props

  return (
    <StyledBlogCard to={"/blog/" + slug + "/"} className={className}>
      <div className="card__title font-weight--500">
        {categories && <h4>{categories[0]}</h4>}
        <div className="color--midnight font-weight--500">
          <p>{title}</p>
        </div>
      </div>

      <p className="card__icon paragraph--small">
        <IconArrow />
        <span>
          <Trans>Read this article</Trans>
        </span>
      </p>
    </StyledBlogCard>
  )
}

export default BlogCard
