import React from "react"

// Libraries
// import { graphql, useStaticQuery } from "gatsby"
import styled from "styled-components"
import { Trans, useTranslation } from "gatsby-plugin-react-i18next"

// Utils
import breakpoint from "utils/breakpoints/"
import { colors } from "utils/variables/"

// Components
import Container from "components/container/"
import Grid from "components/grid/"
import BlogCard from "components/blog-card-home/"
import Button from "components/button/"

const StyledBlog = styled.section`
  padding: 80px 0;

  ${breakpoint.medium`
    padding: 120px 0;
  `}

  h1 {
    margin-bottom: 8px;

    ${breakpoint.medium`
      margin-bottom: 16px;
    `}
  }

  .blog__see-all {
    display: flex;
    justify-content: center;
  }
`

const StyledGrid = styled(Grid)`
  margin: 40px 0 32px 0;

  ${breakpoint.medium`
    margin-bottom: 80px;
  `}

  .grid__item {
    margin-bottom: 0;

    &:nth-child(2),
    &:nth-child(3) {
      display: none;
    }

    ${breakpoint.small`
      width: calc((100% - 16px) / 2);

      &:nth-child(2) {
        display: block;
      }
    `}

    ${breakpoint.large`
      width: calc((100% - (32px * 2)) / 3);

      &:nth-child(3) {
        display: block;
      }
    `}
  }
`

const Blog = props => {
  const { data: posts } = props
  const { t } = useTranslation()

  return (
    <StyledBlog>
      <Container>
        <h1>
          <Trans>We have a lot to say</Trans>
        </h1>
        <p>
          <Trans>
            Articles about human-centered design by members of the Indi team.
          </Trans>
        </p>
        <StyledGrid columns="3" gutter="32px">
          {posts.map(post => (
            <div className="grid__item" key={post.title}>
              <BlogCard {...post} />
            </div>
          ))}
        </StyledGrid>

        <div className="blog__see-all">
          <Button
            to="/blog/"
            backgroundColor={colors.indiblue}
            hoverBackgroundColor={colors.midnight}
            text={t("Discover all the articles")}
          />
        </div>
      </Container>
    </StyledBlog>
  )
}

export default Blog
