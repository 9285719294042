import React from "react"

// Libraries
import { graphql } from "gatsby"

// Utils
import { colors } from "utils/variables/"

// Layout
import Layout from "layouts/layout-primary.js"

// Context
import ContextConsumer from "components/context"

// Page Sections
import Hero from "sections/homepage/hero"
import WhoWeAre from "sections/homepage/who-we-are"
import CaseStudies from "sections/homepage/case-studies"
import Services from "sections/homepage/services"
import Blog from "sections/homepage/blog"
import Team from "sections/homepage/team"
import { useTranslation } from "react-i18next"
import Seo from "components/seo"
// import caseStudies from "data/case-studies"
// import Projects from "sections/work/case-studies"

const Homepage = ({ data }) => {
  const { t } = useTranslation()

  const {
    blogPosts: { nodes: blogPosts },
    allContentfulCaseStudy: { nodes: newCaseStudies },
  } = data

  console.log(newCaseStudies)
  return (
    <Layout menuColor={colors.indiblue}>
      <ContextConsumer>
        {({ data, set }) => {
          if (data.useYellowFooter) {
            set({ useYellowFooter: false })
          }
          if (data.darkMenu) {
            set({ darkMenu: false })
          }
          if (!data.showFooter) {
            set({ showFooter: true })
          }
          if (data.simplifiedFooter) {
            set({ simplifiedFooter: false })
          }
        }}
      </ContextConsumer>
      <Seo
        title={t("Indicius - Design Agency")}
        description={t(
          "We’re a team of humans with the strategy, tools, and talent to build outstanding branding solutions and digital products."
        )}
      />
      <Hero />
      <WhoWeAre />
      <CaseStudies data={newCaseStudies} />
      <Services />
      <Blog data={blogPosts} />
      <Team />
    </Layout>
  )
}

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }

    blogPosts: allContentfulBlogPost(
      sort: { fields: publishing, order: DESC }
      filter: { node_locale: { eq: $language } }
      limit: 3
    ) {
      nodes {
        id
        slug
        title
        categories
      }
    }

    allContentfulCaseStudy(filter: { node_locale: { eq: $language } }) {
      nodes {
        title
        client
        slug
        year
        id
        node_locale
        categories: tags
        new
        imagen: covers {
          gatsbyImageData(height: 320, width: 464, quality: 100)
          file {
            contentType
            url
          }
        }
      }
    }
  }
`

export default Homepage
