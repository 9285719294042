import React, { useState, useEffect } from "react"

// Libraries
import styled, { keyframes } from "styled-components"
import { Trans } from "gatsby-plugin-react-i18next"

// Utils
import breakpoint from "utils/breakpoints/"
import { colors, fonts } from "utils/variables/"

// Components
import Container from "components/container/"
import Lottie from "components/lottie/"

const Animation = keyframes`
  from {
    color: ${colors.white};
  }

  to {
    color: ${colors.supernova};
  }
`

const StyledHero = styled.section`
  width: 100%;
  height: 100vh;
  position: relative;
  display: flex;
  align-items: center;
  background-color: ${colors.indiblue};
  color: ${colors.white};
  overflow: hidden;

  h1 {
    max-width: 652px;
    position: relative;
    z-index: 1001;

    span {
      color: ${props => (props.visible ? colors.supernova : colors.white)};
      font-family: ${fonts.primary};
      font-weight: 500;
      animation: ${Animation} 1.5s ease-in-out 0.6s forwards;
    }
  }

  .hero__animation {
    position: absolute;
    right: 0;
    bottom: -1px;
    left: 0;
    display: flex;
    transition: all 2s;

    ${breakpoint.medium`
      top: 0;
    `}
  }
`

const Hero = () => {
  const [animation, setAnimation] = useState(null)

  useEffect(() => {
    const fetchAnimation = async () => {
      const mobileAnimations = [
        "animations/home/hero-mobile-animation-1.json",
        "animations/home/hero-mobile-animation-2.json",
      ]

      const desktopAnimations = [
        "animations/home/hero-desktop-animation-1.json",
        "animations/home/hero-desktop-animation-2.json",
      ]

      let _animation = null

      if (window.innerWidth <= 1024) {
        const random =
          mobileAnimations[Math.floor(Math.random() * mobileAnimations.length)]
        _animation = await fetch(
          window.location.origin + "/" + random
        ).then(response => response.json())
      } else {
        const random =
          desktopAnimations[
            Math.floor(Math.random() * desktopAnimations.length)
          ]
        _animation = await fetch(
          window.location.origin + "/" + random
        ).then(response => response.json())
      }

      setAnimation(_animation)
    }

    fetchAnimation()
  }, [])

  return (
    <StyledHero>
      <Container>
        <h1>
          <Trans>Designing for a more human-centered world.</Trans>
          <br />
          <span>
            <Trans>Together.</Trans>
          </span>
        </h1>
      </Container>
      <div className="hero__animation">
        {animation && (
          <Lottie
            loop
            autoplay
            animation={animation}
            isClickToPauseDisabled={true}
          />
        )}
      </div>
    </StyledHero>
  )
}

export default Hero
