import React, { useRef } from "react"

// Libraries
import styled from "styled-components"
import { useTranslation } from "gatsby-plugin-react-i18next"

// Utils
import breakpoint from "utils/breakpoints/"
import { colors } from "utils/variables/"

// Components
import Container from "components/container/"
import Button from "components/button/"
import caseStudies from "data/case-studies"
import CaseStudyCard from "components/case-study-card"

const StyledProjects = styled.section`
  padding-bottom: 64px;

  ${breakpoint.medium`
    padding-bottom: 80px;
  `}

  .case-study {
    margin-bottom: 32px;

    &:last-child {
      margin-bottom: 0;
    }

    ${breakpoint.small`
      margin-bottom: 40px;
    `}
  }

  .see-more {
    margin: 32px auto 0 auto;
    text-align: center;

    ${breakpoint.small`
      margin-top: 56px;
    `}
  }
`

const Projects = props => {
  const { t } = useTranslation()

  const { language } = props

  const { data: additionalCaseStudies } = props

  const ref = useRef()

  const mergedCaseStudies = [...additionalCaseStudies, ...caseStudies]

  return (
    <StyledProjects ref={ref} language={language}>
      <Container>
        {mergedCaseStudies.slice(0, 2).map(caseStudy => (
          <div className="case-study d-flex justify-content-center">
            <CaseStudyCard {...caseStudy} />
          </div>
        ))}

        <div className="see-more">
          <Button
            to="/work/"
            backgroundColor={colors.indiblue}
            color={colors.white}
            hoverBackgroundColor={colors.midnight}
            text={t("View all case studies")}
          />
        </div>
      </Container>
    </StyledProjects>
  )
}

export default Projects
