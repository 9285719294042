import React, { useEffect, useState } from "react"

// Libraries
import styled, { keyframes } from "styled-components"
import Lottie from "components/lottie/"
import { Trans, useTranslation } from "gatsby-plugin-react-i18next"

// Utils
import breakpoint from "utils/breakpoints/"
import { colors, fonts } from "utils/variables/"

// Components
import Container from "components/container/"
import LinkWithIcon from "components/link-with-icon"

// Icons
import Arrow from "assets/icons/icon-design-sprint-arrow.inline.svg"

// Animations
import DesignSprint from "assets/animations/homepage/design-sprint-animation.json"
import Branding from "assets/animations/homepage/branding-animation.json"
import DigitalProducts from "assets/animations/homepage/digital-products-animation.json"

const StyledServices = styled.section`
  padding: 56px 0 64px 0;
  background-color: ${colors.supernova};
  color: ${colors.stout};

  ${breakpoint.small`
    padding: 64px 0 96px 0;
  `}

  ${breakpoint.medium`
    padding: 124px 0;
  `}

  .container {
    position: relative;
  }

  .carousel__controls {
    display: flex;
    align-items: center;
    justify-content: space-between;

    ${breakpoint.small`
      margin-bottom: 24px;
    `}

    ${breakpoint.large`
      width: 100%;
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      z-index: 8000;
    `}

    .navigation-arrows {
      display: flex;
      align-items: center;

      .arrow {
        display: flex;
        cursor: pointer;

        &.arrow--next {
          transform: rotate(180deg);
        }

        &:hover {
          svg {
            * {
              fill: ${colors.watermelon};
            }
          }
        }

        svg {
          width: 40px;
          height: auto;

          * {
            transition: all 0.3s;
          }
        }
      }
    }
  }

  .design-sprint__carousel {
    display: flex;
    overflow: hidden;

    .slide {
      width: 100%;
      flex-shrink: 0;
      transition: all .5s;

      .slide__inner {
        max-width: 1120px;
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        padding: 0 24px;
        margin: 0 auto;

        ${breakpoint.small`
          flex-wrap: nowrap;
          align-items: center;
          justify-content: space-between;
          padding: 0 48px;
        `}

        ${breakpoint.large`
          padding: 0;
        `}
      }

      .slide__image {
        order: 0;
        width: 100%;
        height: 310px;
        position: relative;
        display: flex;
        align-items: center;

        @media screen and (max-width: 767px) {
          max-width: 320px;
          max-height: 310px;
          margin: 0 auto;
        }

        ${breakpoint.small`
          order: 1;
          width: 320px;
          height: 305px;
        `}

        ${breakpoint.medium`
          width: 424px;
          height: 406px;
        `}

        ${breakpoint.large`
          width: 640px;
          height: 620px;
        `}

        ${breakpoint.extraLarge`
          width: 660px;
          height: 605px;
        `}

        > div {
          width: 100%;
          height: auto;
          position: relative;
        }

        &[data-services="1"] {
          & > div:nth-child(1) {
            opacity: 1;
            z-index: 1000;
          }

          & > div:nth-child(2),
          & > div:nth-child(3) {
            opacity: 0;
            z-index: 1;
          }
        }

        &[data-services="2"] {
          & > div:nth-child(2) {
            opacity: 1;
            z-index: 1000;
          }

          & > div:nth-child(1),
          & > div:nth-child(3) {
            opacity: 0;
            z-index: 1;
          }
        }

        &[data-services="3"] {
          & > div:nth-child(3) {
            opacity: 1;
            z-index: 1000;
          }

          & > div:nth-child(1),
          & > div:nth-child(2) {
            opacity: 0;
            z-index: 1000;
          }
        }

        & > div {
          width: 100%;
          height: auto!important;
          position: absolute;
          top: 0;
          left: 0;
          opacity: 0;
          transition: all 1s ease;

          &.active {
            opacity: 1;
          }
        }
      }

      .slide__content {
        order: 1;
        width: 100%;

        ${breakpoint.small`
          order: 0;
          width: 340px;
        `}

        ${breakpoint.medium`
          width: 424px;
        `}

        ${breakpoint.large`
          width: 520px;
        `}

        h1 {
          display: flex;
          flex-direction: column;
          height: 1.2em;
          overflow: hidden;
          
          span {
            transform: translateY(calc(-1.2em * (${props =>
              props.currentSlide} - 1)));
            transition: transform 1s ease;
          }
        }

        .together {
          font-family: ${fonts.primary};
          font-weight: 500;
        }

        .descriptions {
          width: 100%;
          height: 135px;
          display: flex;
          flex-direction: column;
          margin: 16px 0 24px 0;
          overflow: hidden;

          ${breakpoint.medium`
            height: 160px;
            margin: 40px 0 46px 0;
          `}

          p {
            width: 100%;
            height: 100%;
            margin-bottom: 65px;
            flex-shrink: 0;
            transform: translateY(calc(-200px * (${props =>
              props.currentSlide} - 1)));
            transition: transform 1s ease;

            &:last-child {
              margin-bottom: 0;
            }

            ${breakpoint.medium`
              margin-bottom: 80px;
              transform: translateY(calc(-240px * (${props =>
                props.currentSlide} - 1)));
            `}
          }
        }
      }
    }
  }
`

const Fill = keyframes`
  from {
    width: 0;
  }

  to {
    width: 100%;
  }
`

const ProgressBar = styled.div`
  width: 160px;
  height: 4px;
  background-color: #ceac10;
  border-radius: 4px;
  overflow: hidden;

  .progress {
    width: 0;
    height: 100%;
    background-color: ${colors.white};
    transition: width 15s ease-in-out;

    &.animated {
      animation: ${Fill} linear 15s infinite;
    }
  }
`

const Services = () => {
  const [currentSlide, setCurrentSlide] = useState(1)
  const [interval, setStateInterval] = useState(null)

  const { t } = useTranslation()

  useEffect(() => {
    const _interval = setInterval(() => {
      handleNext()
    }, 15000)

    setStateInterval(_interval)
  }, [])

  useEffect(() => {
    handleSlideTransition()
  }, [currentSlide])

  const handlePrev = () => {
    if (currentSlide > 1) {
      setCurrentSlide(currentSlide - 1)
    } else {
      setCurrentSlide(3)
    }
  }

  const handleNext = () => {
    if (currentSlide < 3) {
      setCurrentSlide(currentSlide + 1)
    } else {
      setCurrentSlide(1)
    }
  }

  const handleIntervalReset = () => {
    // Resets inverval
    const _interval = setInterval(() => {
      handleNext()
    }, 15000)

    clearInterval(interval)

    setStateInterval(_interval)

    // Resets progress bar
    const progressBarWrapper = document.getElementById("progressBarWrapper")
    const oldProgressBar = document.getElementById("progressBar")
    const newProgressBar = oldProgressBar.cloneNode()

    progressBarWrapper.removeChild(oldProgressBar)
    progressBarWrapper.appendChild(newProgressBar)
  }

  const handleSlideTransition = () => {
    handleIntervalReset()
  }

  return (
    <StyledServices currentSlide={currentSlide}>
      <Container className="container">
        <div className="carousel__controls">
          <ProgressBar id="progressBarWrapper">
            <div id="progressBar" className="progress animated"></div>
          </ProgressBar>

          <div className="navigation-arrows">
            <button
              aria-label="Previous Service"
              type="button"
              className="arrow arrow--prev"
              onClick={handlePrev}
            >
              <Arrow />
            </button>

            <button
              aria-label="Next Service"
              type="button"
              className="arrow arrow--next"
              onClick={handleNext}
            >
              <Arrow />
            </button>
          </div>
        </div>
      </Container>

      <div className="design-sprint__carousel" id="designSprintCarousel">
        <div className="slide">
          <div className="slide__inner">
            <div className="slide__content">
              <h1>
                <span className="active" data-services="1">
                  <Trans>Design Sprint.</Trans>
                </span>
                <span data-services="2">
                  <Trans>Branding.</Trans>
                </span>
                <span data-services="3">
                  <Trans>Digital Products.</Trans>
                </span>
              </h1>
              <h1 className="together">
                <Trans>Together.</Trans>
              </h1>

              <div className="descriptions">
                <p data-services="1">
                  <Trans>
                    Solve tough problems in a single week through this
                    structured, step-by-step user experience design workshop
                    used by the most innovative companies in the world.
                  </Trans>
                </p>
                <p data-services="2">
                  <Trans>
                    Create a visual identity for your company, and an overall
                    brand experience between you and your customers.
                  </Trans>
                </p>
                <p data-services="3">
                  <Trans>
                    We have a solid user-centered research and design process to
                    create outstanding interfaces for digital products.
                  </Trans>
                </p>
              </div>

              <LinkWithIcon
                to="/services/"
                text={t("Discover our services & processes")}
                iconColor={colors.indiblue}
              />
            </div>

            <div className="slide__image" data-services={currentSlide}>
              <Lottie animation={DesignSprint} autoplay loop />
              <Lottie animation={Branding} autoplay loop />
              <Lottie animation={DigitalProducts} autoplay loop />
            </div>
          </div>
        </div>
      </div>
    </StyledServices>
  )
}

export default Services
